<template>
  <!-- 路内停车场 ————> 查看视频桩 -->
  <div class="box-card" style="text-align: left;margin-top:0;">
    <!-- 主要内容 -->
    <div class="content">
      <el-form label-position="right" label-width="120px" :model="detailsList" class="left">
        <!-- <div class="title">基本信息</div>
        <el-divider></el-divider> -->
        <el-form-item label="设备名称：">{{detailsList.video_pile_name}}</el-form-item>
        <el-form-item label="设备品牌：">{{detailsList.video_pile_brand_name}}</el-form-item>
        <el-form-item label="设备型号：">{{detailsList.video_pile_model_name}}</el-form-item>
        <!-- <div class="title" style="margin-top:100px;">硬件属性</div>
        <el-divider></el-divider> -->
        <el-form-item label="序列号：">{{detailsList.video_pile_serial}}</el-form-item>
        <!-- <el-form-item label="硬件版本：">{{detailsList.hardware_version}}</el-form-item>
        <el-form-item label="软件版本：">{{detailsList.software_version}}</el-form-item>
        <el-form-item label="模组编号：">{{detailsList.imei}}</el-form-item> -->
        <el-form-item label="设备状态：">
          <div :style="detailsList.device_state == 2 ? 'color: #ff0000;' : 'color: #18943b;'">{{detailsList.device_state == 2 ? "离线" : "在线" }}</div>
        </el-form-item>
        <el-form-item label="状态更新时间：">
          {{ $moment(detailsList.heartbeat_time).format("YYYY-MM-DD HH:mm:ss") }}
        </el-form-item>
      </el-form>
      <div class="right">
        <el-image :src="detailsList.video_pile_model_pic" v-if="detailsList.video_pile_model_pic">
        </el-image>
        <div class="innerText">设备图片</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getVideoPileDetails } from '@/api/deviceApi'
import { mapMutations, mapState } from 'vuex'
export default {
  props: { video_pile_id: { required: true } },
  data () {
    return {
      detailsList: {}
      // video_pile_id: null
    }
  },
  created () {
    // this.video_pile_id = this.$route.query.video_pile_id
    this.getVideoPileDetails()
  },
  mounted () { },
  computed: {
    ...mapState('menuList', ['isRoutW'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 视频桩详情
    async getVideoPileDetails () {
      const res = await getVideoPileDetails({
        video_pile_id: this.video_pile_id
      })
      // console.log(res);
      this.detailsList = res.Data
    },
    //   返回视频桩页面
    goBack () {
      this.setQueryStatus(2)
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="less">
.content {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.title {
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  color: #000;
}
.content .left {
  width: 70%;
}
.left .el-form-item__content {
  text-align: left;
}
.left .el-row {
  width: 100%;
}
.left .el-form-item {
  margin-bottom: 0px;
}
.content {
  /deep/ .right {
    width: 40%;
    // height: 500px;
    text-align: right;
    background-color: #FAFAFA;
    text-align: center;
    .innerText{
      width: 100%;
      text-align: center;
      color: #fff;
      background-color: #7D7D7D;
    }
    .el-image {
      width: 100%;
      height: inherit;
      .el-image__inner {
        width: auto !important;
        height: auto !important;
        max-height: 400px;
      }
      /deep/.el-image__error {
        width: 300px;
        height: 300px;
      }
    }
  }
}
// .content .right {
//   width: 20%;
//   text-align: right;
//   /deep/ .el-image__inner {
//     max-height: 500px;
//   }
//   /deep/.el-image__error {
//     width: 300px;
//     height: 300px;
//   }
// }
</style>
